import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import("@/views/index.vue"),
    children: [
      {
        name: "home",
        path: "/home",
        component: () => import("@/views/home"),
      },
      {
        name: "about",
        path: "/about",
        component: () =>
          import("@/views/about"),
        children: [
          {
            name: "aboutUs",
            path: "/about/us",
            component: () => import("@/views/about/aboutUs"),
          },
          {
            name: "publicity",
            path: "/about/publicity",
            component: () => import("@/views/about/publicity"),
          },
          {
            path: "/",
            redirect: "/about/us",
          },
        ],
      },
      {
        name: "adjust",
        path: "/adjust",
        component: () =>
          import("@/views/adjust"),
        children: [
          {
            name: "rule",
            path: "/adjust/rule",
            component: () => import("@/views/adjust/rule"),
          },
          {
            name: "program",
            path: "/adjust/program",
            component: () => import("@/views/adjust/program"),
          },
          {
            path: "/",
            redirect: "/adjust/rule",
          },
        ],
      },
      {
        name: "news",
        path: "/news",
        component: () =>
          import("@/views/news"),
        children: [
          {
            name: "realTime",
            path: "/news/realTime",
            component: () => import("@/views/news/realTime"),
          },
          {
            name: "realTime",
            path: "/news/policy",
            component: () => import("@/views/news/policy"),
          },
          {
            name: "newsDetail",
            path: "/news/newsDetail",
            component: () => import("@/views/news/newsDetail"),
          },
          {
            path: "/",
            redirect: "/news/realTime",
          },
        ],
      },
      {
        path: "/",
        redirect: "/home",
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
